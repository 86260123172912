import React, { useEffect, useState, useRef } from 'react';

import { useI18next } from '../../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import {
    main,
    content,
    button,
    productWrapper,
    productWrapperAnimate,
    backgroundImage,
    titleContainer,
    title,
    subtitle,
    topPadding,
    desktop,
    mobile,
    visible,
    hidden,
    contentWrapper,
    contentLeft,
    contentFirst,
    contentTitle,
    secondWrapper,
    secondWrapperTitle,
    buttonNew,
    quoteFirst,
    contentFirstMobile,
    quoteTwo,
    rotateCounterAnimation2,
    rotateCounterAnimation3,
    rotateCounterAnimation4,
    rotateButton,
    changeCountProducts
} from './home-products.module.scss';
import AboutBackground from '../../../../assets/images/svg/Fashion-fairy-logo.svg';
import AboutBackgroundMobile from '../../../../assets/images/svg/modatoty-mobile.svg';

import Section from '../../../molecules/section';
import getTranslationKey from '../../../../utils/get-translation-key';
import Link from '../../../atoms/link';
import SectionWrapper from '../../../molecules/section-wrapper';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Button from '../../../atoms/button';
import Quote from '../../../molecules/quote';
import { ISliderProduct } from '../../../../models/slider-product.model';
import RotateClockwise from '../../../../assets/images/svg/rotateClockwise.svg';
import ExpandArrow from '../../../../assets/images/svg/expand-arrow.svg';

export interface HomeProductsProps {
    withTopPadding?: boolean;
    products: ISliderProduct[];
}

export default function HomeProducts({ withTopPadding, products }: HomeProductsProps) {
    const { t } = useI18next();
    const [
        backgroundRef,
        backgroundEntry,
    ] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.1,
    });
    const [contentRef, contentEntry] = useIntersectionObserver<HTMLDivElement>({
        threshold: 0.5,
    });
    const aboutTranslations = getTranslationKey('home', 'clothes');

    const [visibleProducts, setVisibleProducts] = useState(products);
    const [isAnimating, setIsAnimating] = useState(false);

    const [position, setPosition] = useState(0);
    const containerRef = useRef(null);


    const [productsVisibleCount, setProductsVisibleCount] = useState(3);

    useEffect(() => {
        const handleScroll = () => {
            if (containerRef.current) {
                const containerTop = containerRef.current.offsetTop;
                const container = containerRef.current;
                console.log("bounding top: ", container.getBoundingClientRect().top);
                const containerBottom =
                    containerRef.current.offsetTop + containerRef.current.offsetHeight;
                const scrollY = window.scrollY;
                console.log(scrollY, containerTop, containerBottom);
                // Ustaw pozycję, jeśli w granicach kontenera
                if (scrollY >= containerTop && scrollY <= containerBottom - 100) {
                    setPosition((scrollY - containerTop) + 100);
                } else if (scrollY < containerTop) {
                    setPosition(0); // Na górze kontenera
                }
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const rotateCounterClockwise = () => {
        if (isAnimating) return;
        setIsAnimating(true);

        setTimeout(() => {
            const rotatedProducts = [...visibleProducts];

            // Zamień elementy o indeksach 0, 1, 2 i 3
            rotatedProducts[0] = visibleProducts[1]; // Index 1 → 0
            rotatedProducts[1] = visibleProducts[3]; // Index 3 → 1
            rotatedProducts[2] =
                visibleProducts.length > 4 ? visibleProducts[4] : visibleProducts[0]; // Index 4 → 2 (lub 0)
            rotatedProducts[3] = visibleProducts[2]; // Index 2 → 3

            // Przenieś element z indeksu 0 na koniec
            rotatedProducts.push(visibleProducts[0]);

            // Usuń element z indeksu 0 z początku tablicy
            rotatedProducts.splice(4, 1);
            setVisibleProducts(rotatedProducts);
            setIsAnimating(false);
        }, 980); // Czas musi pasować do czasu animacji w CSS
    };

    const changeCountProductsVisible = () => {
        setProductsVisibleCount((prev) => {
            return prev + 3;
        });
    }
    return (
        <>
            <SectionWrapper
                ref={containerRef}
                bgColor={'violet-dark'}
                className={`${main} ${withTopPadding ? topPadding : ''}`}
            >

                <div className={rotateButton} onClick={rotateCounterClockwise}
                    style={{
                        top: `${position}px`,
                    }}>
                    <RotateClockwise></RotateClockwise>
                </div>
                {visibleProducts.map((product, index) => (
                    <>
                        <div className={`${contentWrapper} ${productWrapper} 
                        ${isAnimating && index == 1 ? rotateCounterAnimation2 : ""} 
                        ${isAnimating && index == 2 ? rotateCounterAnimation3 : ""} 
                        ${isAnimating && index == 3 ? rotateCounterAnimation4 : ""} 
                        ${index % 2 === 0 ? "" : secondWrapper}`}
                            key={`productSlide-${product.productId}`}
                            style={{ backgroundImage: `url(${product.imgUrl})`, display: index > productsVisibleCount ? 'none' : 'flex' }}    >
                            {/* <div className={secondWrapperTitle}>

                            </div> */}
                            <div className={contentLeft}>
                                <a href={product.affiliateUrl} className={button} target={'_blank'}>
                                    <h2>Zobacz</h2>
                                </a>
                            </div>
                        </div >
                    </>
                ))
                }
                {productsVisibleCount < visibleProducts.length && 
                <div className={changeCountProducts}>
                    <ExpandArrow onClick={changeCountProductsVisible}></ExpandArrow>
                </div>}

            </SectionWrapper >
        </>
    );
}
