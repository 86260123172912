import React from "react";
import { GatsbyImage, getImage, ImageDataLike } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import {
    intro,
    introPhoto,
    visible,
    hide,
    icon,
    content,
    heading,
    text,
    section,
    quotation,
    signature,
    aboutBox,
    aboutLeftWrapper,
    aboutRightWrapper,
    aboutBox2,
    about2LeftWrapper,
    about2RightWrapper,
    aboutBox3,
    about3LeftWrapper,
    about3RightWrapper,
} from "./about-us.module.scss";
import Icon from "../../assets/images/svg/witaj.svg";
import usePageMetadata from "../../hooks/use-page-metadata";
import useIntersectionObserver from "../../hooks/useIntersectionObserver";
import { IPageBase } from "../../models/page-base.model";
import { ISite } from "../../models/site.model";

import MainLayout from "../../layouts/main-layout";
import AboutSection from "../organisms/sections/about-us/about-section";
import Section from "../molecules/section";
import SectionWrapper from "../molecules/section-wrapper";
import Title from "../atoms/title";
import FooterStylizeSection from "../molecules/footer-stylize-section";
import TwoBoxSection from "../organisms/sections/common/two-box-section";
import DescriptionSection from "../organisms/sections/common/description-section";

interface IAboutUsProps extends IPageBase {
    readonly data: { site: ISite } & { [key: string]: ImageDataLike };
    className?: string;
}

const AboutUs: React.FC<IAboutUsProps> = ({ data, pageContext }) => {
    const { introImage, aboutEmilia, aboutFashion, site } = data;
    const aboutUsImages = getImage(introImage);

    const { pageTitle, defaultStructuredData } = usePageMetadata(pageContext, site);

    const sectionElements = [
        {
            id: 60,
            title:
                "Niech Twoje marzenia przyjmą realną postać w modzie, a reszta ich sama się spełni.",
            content:
                "Oglądając tysiące zdjęć ubrań w Internecie bardzo łatwo się pogubić. Szukając czegoś dla siebie, mijają godziny, czasem dni, a my chcemy i potrzebujemy coś fajnego, coś nowego, coś do nas dopasowanego, na specjalną okazję lub bez.. Tak jest. \n" +
                "Szukamy i szukamy, im dłużej przeglądamy kolejne ciuchy tym bardziej jesteśmy znudzeni i znużeni, że znów to nie to lub nie możemy się zdecydować. Frustracja narasta, a nowa stylizacja nas przerasta.\n" +
                "I dlatego właśnie powstaliśmy MY!!",
            image: getImage(aboutEmilia),
        },
        {
            isReversed: true,
            id: 61,
            title: "Dobrze się ubrałam? Dzięki nam będziesz już wiedziała, że na pewno TAK.",
            content:
                "<p>Ten unikalny projekt powstał z myślą o osobach, które chcą:</p> " +
                " <ul> <li> - stworzyć swój niepowtarzalny styl lub dopracować ten, który już mają bez wychodzenia z domu, </li> " +
                "<li>- zamiast przeszukiwać dziesiątki sklepów, chcą mieć wszystkie swoje specjalnie dobrane ubrania przez stylistkę dla siebie w jednym miejscu,</li> " +
                "<li>- nauczysz się świadomości poczucia dobrego stylu, oraz wydobędziesz swoje atuty, </li> " +
                "<li>- poznasz świat mody od podszewki, </li> " +
                "<li>- będziesz na bieżąco z obecnymi trendami, </li> " +
                "<li> - już nigdy nie popełnisz modowego faux pas, </li> " +
                "<li> - będziesz mistrzynią odczytywania drees codu na każdą okazję, </li> " +
                "<li>  i wiele innych. </li> </ul> ",
            image: getImage(aboutFashion),
        },
    ];

    const [sectionRef, sectionEntry] = useIntersectionObserver<HTMLDivElement>({ threshold: 0.1 });

    return (
        <MainLayout
            SEOProps={{
                title: pageTitle,
                structuredData: defaultStructuredData,
            }}
        >
            <TwoBoxSection
                className={aboutBox}
                classNameLeftWrapper={aboutLeftWrapper}
                classNameRightWrapper={aboutRightWrapper}
                title={"To ja Fashion Fairy"}
                text={<>
                    Mam na imię Emilia. Jestem profesjonalną stylistką oraz specjalistką od
                    wizerunku. Moja wiedza i powołanie, kazało mi przenieść to co najcenniejsze
                    w świecie mody do świata cyberprzestrzeni. Dzięki współpracy ze stylistami i
                    programistami, połączyliśmy to co do tej pory wydawało się nieosiągalne -
                    Wirtualną Stylistkę, Twoją własną, stworzoną wg Twoich potrzeb, stylu,
                    figury, urody, po to, byś zawsze czuła się dobrze ze sobą, zachwycając swym
                    lookiem, niczym milion dolarów. Zdobędziesz też specjalistyczną wiedzę,
                    która jest często przekazywana tylko w profesjonalnych szkołach związanych z
                    modą. Wejdź w tą wciągającą podróż, a zobaczysz, że niedługo świat mody
                    stanie się dla Ciebie tak piękny i zarazem jasny, że już nigdy nie powiesz -
                    „nie mam się w co ubrać!”.
                </>} />
            <TwoBoxSection
                isReverse={true}
                className={aboutBox2}
                classNameLeftWrapper={about2LeftWrapper}
                classNameRightWrapper={about2RightWrapper}
                title={"Niech Twoje marzenia przyjmą realną postać w modzie, a reszta ich sama się spełni."}
                text={<>
                    Oglądając tysiące zdjęć ubrań w Internecie bardzo łatwo się pogubić. Szukając czegoś dla siebie, mijają godziny, czasem dni, a my chcemy i potrzebujemy coś fajnego, coś nowego, coś do nas dopasowanego, na specjalną okazję lub bez.. Tak jest. <br />
                    Szukamy i szukamy, im dłużej przeglądamy kolejne ciuchy tym bardziej jesteśmy znudzeni i znużeni, że znów to nie to lub nie możemy się zdecydować. Frustracja narasta, a nowa stylizacja nas przerasta.<br />
                    I dlatego właśnie powstaliśmy MY!!
                </>} />
            <TwoBoxSection
                className={aboutBox3}
                classNameLeftWrapper={about3LeftWrapper}
                classNameRightWrapper={about3RightWrapper}
                title={"Dobrze się ubrałam? Dzięki nam będziesz już wiedziała, że na pewno TAK"}
                text={<>
                    <p>Ten unikalny projekt powstał z myślą o osobach, które chcą:</p>
                    <ul> <li> - stworzyć swój niepowtarzalny styl lub dopracować ten, który już mają bez wychodzenia z domu, </li>
                        <li>- zamiast przeszukiwać dziesiątki sklepów, chcą mieć wszystkie swoje specjalnie dobrane ubrania przez stylistkę dla siebie w jednym miejscu,</li>
                        <li>- nauczysz się świadomości poczucia dobrego stylu, oraz wydobędziesz swoje atuty, </li>
                        <li>- poznasz świat mody od podszewki, </li>
                        <li>- będziesz na bieżąco z obecnymi trendami, </li>
                        <li> - już nigdy nie popełnisz modowego faux pas, </li>
                        <li> - będziesz mistrzynią odczytywania drees codu na każdą okazję, </li>
                    </ul>
                </>} />

            <DescriptionSection></DescriptionSection>
        </MainLayout>
    );
};

export const query = graphql`
    query($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        introImage: file(relativePath: { eq: "welcome-photo.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        aboutEmilia: file(relativePath: { eq: "emiliaFirst.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        aboutFashion: file(relativePath: { eq: "aboutFashion.jpg" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }
        logo: file(relativePath: { eq: "emilka.png" }) {
            childImageSharp {
                gatsbyImageData(placeholder: BLURRED, quality: 100, layout: FULL_WIDTH)
            }
        }

        site {
            ...siteFields
        }
    }
`;

export default AboutUs;
